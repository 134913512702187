import { PhoneIcon, EnvelopeIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { graphql, PageProps } from 'gatsby';
import Img, { FluidObject } from 'gatsby-image';

import { tailwind } from '../../gatsby-data';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Heading from '../components/heading';

interface ContactLink {
  id: number;
  url?: string;
  icon: typeof PhoneIcon;
  alt: string;
  title: string;
}

const CONTACT_DETAILS: ContactLink[] = [
  {
    id: 0,
    icon: PhoneIcon,
    alt: 'Phone number',
    title: '+1 (858) 754-8530',
  },
  {
    id: 1,
    icon: EnvelopeIcon,
    alt: 'Email',
    title: 'george@georgemarshall.name',
  },
];

type Props = Queries.AboutPageQuery;

const AboutPage = ({ data }: PageProps<Props>) => (
  <Layout>
    <Heading
      title="About"
      image={(data.background?.childImageSharp?.fluid as FluidObject) ?? undefined}
    />
    <div className="mx-auto max-w-screen-xl px-4 py-12 sm:px-6 lg:px-8 lg:pb-24">
      <div className="space-y-4 sm:grid sm:grid-cols-3 sm:items-start sm:gap-6 sm:space-y-0">
        {/*
          <div className="aspect-w-3 aspect-h-2 sm:aspect-w-3 sm:aspect-h-4">
            <img
              className="object-cover shadow-lg rounded-lg"
              src={aboutPhoto}
              alt={data.site?.siteMetadata.title}
            />
          </div>
          */}
        <aside className="pb-2">
          <Img
            className="aspect-h-2 aspect-w-3 sm:aspect-h-4 sm:aspect-w-3"
            imgStyle={{
              boxShadow: tailwind.theme?.boxShadow?.lg,
              borderRadius: tailwind.theme?.borderRadius?.lg,
              objectFit: 'cover',
            }}
            fluid={(data.photo?.childImageSharp?.fluid as FluidObject) ?? []}
            alt={data.site?.siteMetadata.title}
          />

          <h4 className="mt-8 text-2xl font-bold leading-8 tracking-tight text-gray-900 sm:text-3xl sm:leading-9">
            Get in touch
          </h4>
          <dl className="mt-8 break-all text-base leading-6 text-gray-500">
            {CONTACT_DETAILS.map(({ id, url, icon: Icon, alt, title }) => (
              <div key={id} className="mt-3">
                <dt className="sr-only">{alt}</dt>
                <dd className="flex">
                  <Icon className="h-6 w-6 flex-shrink-0 text-gray-400" />
                  {url ? (
                    <a className="ml-3" href={url}>
                      {title}
                    </a>
                  ) : (
                    <span className="ml-3">{title}</span>
                  )}
                </dd>
              </div>
            ))}
          </dl>
        </aside>

        <div className="sm:col-span-2">
          <div className="space-y-4">
            <div className="prose prose-lg prose-indigo text-gray-500">
              <h3>Myself</h3>
              <p>
                My name is George and this is my personal blog. I use it as a platform to talk about
                the things that interest me, primarily software engineering and snowboarding.
              </p>
              <p>
                I am a passionate and self-driven problem solver with over 14 years of professional
                experience developing software. Possessing the skill sets to effectively step-up as
                a leader and mentor, while remaining a strong individual contributor. All while
                making numerous contributions to open source projects such as{' '}
                <a href="https://www.djangoproject.com/">Django</a>,{' '}
                <a href="https://community.chef.io/products/chef-habitat/">Chef Habitat</a>,{' '}
                <a href="https://jquery.com/">jQuery</a>, and{' '}
                <a href="https://www.saltstack.com/">SaltStack</a>.
              </p>
              <p>
                Outside of computers, I like to get out and hit the slopes! The majority of the
                photos on this site were taken during my adventures. The types of snowboarding I
                enjoy most are freeriding and <abbr title="snowboard cross">boardercross</abbr>.
              </p>
              <h3>The Site</h3>
              <p>
                Things I generally enjoy writing about are my personal projects, as well as some
                professional ones when given permission. The majority of my personal projects
                consist of hardware hacking and learning new technologies.
              </p>
              <p>
                I have recently developed heavily in the{' '}
                <a href="https://www.rust-lang.org/">Rust Programming Language</a>, and enjoy using
                my knowledge to help others grow. My journey into Rust has lead me to learn a lot
                about systems programming and microcontrollers. There will occasionally be posts
                covering projects that target AVR and Xtensa{' '}
                <abbr title="Instruction Set Architecture">ISA</abbr>s as well as some{' '}
                <abbr title="Field-programmable gate array">FPGA</abbr> projects.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export const Head = () => (
  <SEO
    title="About"
    description={
      'My name is George and this website is my personal blog. I use it as a platform to talk about ' +
      'the myriad of things that interest me. Both as a software engineer and snowboarding enthusiast.'
    }
  />
);

export default AboutPage;

export const pageQuery = graphql`
  query AboutPage {
    site {
      siteMetadata {
        title
      }
    }
    background: file(relativePath: { eq: "backgrounds/IMG_3658.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3840) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    photo: file(relativePath: { eq: "photos/about.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
